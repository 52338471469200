import React, {Component} from 'react';
import DataTable from '../../storage/DataTable';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';


import './ArticleHeadline.scss';

import {STREAMS_LIST, STREAMS_DICT} from '../../constants/StreamDisplay';


function timeDiffSeconds(startDate, endDate) {
  return (endDate.getTime() - startDate.getTime()) / 1000;
}

function niceDate(date) {
  const now = new Date();
  let delta = Math.floor(timeDiffSeconds(new Date(date), now) / 60);

  if (delta < 5) return "now";
  if (delta < 60) return (5 * Math.floor(delta/5)) + " minutes ago";
  delta /= 60; delta = Math.floor(delta);
  if (delta < 24) return delta == 1 ? "one hour ago" : delta + " hours ago";
  delta /= 24; delta = Math.floor(delta);
  if (delta < 7) return delta == 1 ? "yesterday" : delta + " days ago";
  delta /= 7; delta = Math.floor(delta);
  if (delta < 4) return delta == 1 ? "last week" : delta + " weeks ago";
  delta /= 4; delta = Math.floor(delta);
  if (delta < 12) return delta == 1 ? "last month" : delta + " months ago";
  delta /= 3; delta = Math.floor(delta);
  return delta == 1 ? "last year" : delta + " years ago";
}




class ArticleHeadline extends Component {
  constructor() {
    super();
  }

  render() {
    const _this = this;
    const article = this.props.article;
    const websites = this.props.websites;
    const stories = this.props.stories;
    const stream = this.props.stream;
    //
    // {article.stories.map((sub) => (
    //   <ArticlesHeadline article={sub} />
    // )}

    const small = this.props.small;

    return (
      <div className="ArticleHeadline" style={{ width: '100%', fontSize: "87%", marginLeft: small ? 0 : 20, marginTop: 10}}>
        <div style={{maxHeight: "2em", overflow: "hidden"}}><img src={(websites[article.domain] || {}).faviconBytes} style={{ height: 14, width: null, flex: 1, marginRight: 5, top: "-7px", position: "relative" }}/>
          <Card.Link className="ArticleTitle2 ArticleAction2" target="_blank" href={article.link}>{article.title}</Card.Link>
        </div>

        <div style={{maxHeight: "1.5em", lineHeight: "1.5em", overflow: "hidden", marginLeft: 20}}>
          <span className="Publisher2 ArticleAction2">
            <span onClick={() => _this.props.searchOrgOrDomain(article.org, article.domain)}>{article.org || (websites[article.domain] || {}).name}</span>
          </span>
          &nbsp;&middot;&nbsp;
          <span className="PublishedDate2">
            {niceDate(article.datePublished)}
          </span>
        </div>
      </div>

    );
  }
}

export default ArticleHeadline;
