const LOG_LEVEL_TRACE = 1;
const LOG_LEVEL_DEBUG = 2;
const LOG_LEVEL_LOG = 3;
const LOG_LEVEL_INFO = 4;
const LOG_LEVEL_WARNING = 5;
const LOG_LEVEL_ERROR = 6;
const LOG_LEVEL_FATAL = 7;
const LOG_LEVEL_DISABLED = 8;

class Logger {
	constructor() {
		this.from_log_level = LOG_LEVEL_TRACE;
  }

	enable(min) {
		this.from_log_level = min;
	}

	write(level, message) {
		if (level < this.from_log_level) return;

		console.log(new Date(), level, message);
	}

	trace(message) {
		this.write(LOG_LEVEL_TRACE, message);
	}

	debug(message) {
		this.write(LOG_LEVEL_DEBUG, message);
	}

	log(message) {
		this.write(LOG_LEVEL_LOG, message);
	}

	info(message) {
		this.write(LOG_LEVEL_INFO, message);
	}

	warning(message) {
		this.write(LOG_LEVEL_WARNING, message);
	}

	error(message) {
		this.write(LOG_LEVEL_ERROR, message);
	}

	fatal(message) {
		this.write(LOG_LEVEL_FATAL, message);
	}
}

var logger = new Logger();

export default logger;
