import CaseStreamProvider from './CaseStreamProvider';
import logger from '../utils/logger';
import articleUtils from '../utils/articleUtils';

// connects though api call to server to get data
class RestDataProvider extends CaseStreamProvider {
  constructor(url = '') {
    super();
    this.baseUrl = url || '';
  }

  _fetch(url, onSuccess, onError) {
    fetch(this.baseUrl + url)
    .then(res => {
      return res.json()
    })
    .then(res => {
      onSuccess(res);
    })
    .catch(error => {
      onError(error);
    });
  }

  _top(topArticles, topKeywords, onSuccess, onError) {
    this._fetch('/latest/top', (r) => {
        console.log("top", r);
        onSuccess(r);
      }, onError);
  }

  _getByStream(stream, start, count, onSuccess, onError) {
    let reverse = true;
    if (stream === 'sxs' || stream === 'realtime' || stream === 'lotto') {
      stream = '_' + stream;
      reverse = false;
    }
    this._fetch(`/latest/${stream}/20190101000000`, (r) => {
        let ret = !reverse ? r.items : r.items.reverse();
        if (stream !== '_sxs' && stream != 'domains') {
          console.log("before", ret);
          ret = articleUtils.buildStoryLine(ret, 3);
          console.log("after", ret);
        }
        onSuccess(ret);
      }, onError);

    // todo ... use rest with pgination
    // this._fetch(`/search?stream=${stream}&start=${start}&count=${count}`, onSuccess, onError);
  }

  _getByTag(tag, start, count, onSuccess, onError) {
    this._fetch(`/search?tag=${tag}&start=${start}&count=${count}`, onSuccess, onError);
  }

  _getByDomain(domain, start, count, onSuccess, onError) {
    this._fetch(`/search?domain=${domain}&start=${start}&count=${count}`, onSuccess, onError);
  }

  _getByOrg(org, start, count, onSuccess, onError) {
    this._fetch(`/search?org=${org}&start=${start}&count=${count}`, onSuccess, onError);
  }

  _getByKeyword(keyword, start, count, onSuccess, onError) {
    this._fetch(`/search?keyword=${keyword}&start=${start}&count=${count}`, onSuccess, onError);
  }
};

export default RestDataProvider;
