import {DB_VERSION} from '../constants/Version';

const DataShapes = {
  "news-rss": {
    "dbName": "news-dev-" + DB_VERSION,
    "dbVersion": 1,
    "keyPath": "url",
    "columns": {
      "url": {
        "unique": true,
      },
      "name": {
      },
      "category": {
      }
    },

    "seed": [
      // {
      //   "url": "www.theblaze.com:443/feeds/for_rss.rss",
      //   "name": "The Blaze",
      //   "category": "Politics",
      //   "ttls": 60*60*1,
      // },
      // {
      //   "url": "https://www.npr.org/rss/rss.php?id=1001",
      //   "name": "NPR",
      //   "category": "Politics",
      //   "ttls": 60*60*1,
      // }
    ]
  },

  "background-job": {
    "dbName": "bj-dev-" + DB_VERSION,
    "dbVersion": 1,
    "keyPath": "_id",
    "autoIncrement": false,
    "columns": {
      "_id": {
        "unique": true,
      },
      "type": {
      },
      "args": {
      },
      "repeatEverySeconds": {
      },
      "timeoutSeconds": {
      }
    },

    "seed": [
      {
        "_id": "console-log:ping from test background job",
        "type": "console-log",
        "args": "ping from test background job",
        "repeatEverySeconds": 10,
        "timeoutSeconds": 5,
      },
      {
        "_id": "fetch-news-main",
        "type": "fetch-news",
        "args": [ "technology",
                  "science",
                  "life",
                  "health",
                  "politics",
                  "business",
                  "entertainment",
                  "sports"
                ],
        "repeatEverySeconds": 60 * 3,
        "timeoutSeconds": 30,
      },
    ]
  },

  "news-article": {
    "dbName": "news-article-dev-" + DB_VERSION,
    "dbVersion": 1,
    "keyPath": "guid",
    "autoIncrement": false,
    "columns": {
      "guid": {"unique": true, multiEntry: false},
      "creator": {},
      "title": {},
      "link": {},
      "datePublished": {},
      "contentSnippet": {},
      "category": {},
      "tags": {multiEntry: true},
      "imageUrl": {},
      "story": {}, //  the story of the article
      "confidenceStory": {},  // confidence this is the right story
      "mainStory": {},  // confidence this is the right story
      "confidenceCategory": {},  //confidence in right category
      "words": {multiEntry: true},
      "cindex": {}, // category index
      "sindex": {}, // stream index
      "gindex": {}, // global index

      // todo, feed data in site data

      "org": {},  // same org can have multipl domains
      "domain": {},

      "dateReceived": {},

      // Added by client:

      "stream": {}, //
      "dateRated": {},
      "rating": {},
      "read": {},

      "clicked": {},  // do not send to server, instead do privacy aware ML on client
                      // send multiple recommendation engine settings
                      // evaluate them, and send the score back
      "inspected": {},
    },
    "seed": [
    ]
  },

  // maybe I do not need to store this on the client, in the client just
  // keep the category hits
  // todo .. load from API? Build list on server?
  // https://www.alexa.com/topsites/category
  // https://moz.com/top500
  // https://en.wikipedia.org/wiki/List_of_most_popular_websites
  // https://www.similarweb.com/top-websites

  "website": {
    "dbName": "top-websites-dev-" + DB_VERSION,
    "dbVersion": 1,
    "keyPath": "domain",
    "autoIncrement": false,
    "columns": {
      "domain": {"unique": true},
      // "name": {},
      "faviconUrl": {}, // todo in site stream
      "faviconBytes": {dontIndex: true}, // todo in site stream
      // "description": {},
      "mainSiteLink": {},
      "imageUrl": {},
      "org": {},
      "dateReceived": {}
    },
    "seed": [
    ]
  },

  "story": {
    "dbName": "story-dev-" + DB_VERSION,
    "dbVersion": 1,
    "keyPath": "story",
    "autoIncrement": false,
    "columns": {
      "story": {"unique": true},
      "stream": {},
      "dateReceived": {},
      "index": {},
    },
    "seed": [
    ]
  }

};

export default DataShapes;
