const ignoreWord = require('./ignoreWord.js');
const natural = require('natural');
const synonyms = require("synonyms");

var ROOTS_CACHE = {};

// get all synonyms, then get the stem for all, return the first alphabetically

function getRoot(word) {
	if (!word) return null;
	word = word.toLowerCase();

	if (ROOTS_CACHE[word]) {
		if (ROOTS_CACHE[word] === '.') return null;
		return ROOTS_CACHE[word];
	}

	if (ignoreWord(word)) {
		ROOTS_CACHE[word] = '.';
		return null;
	}

	let options = [word];
	const syns = synonyms(word);

	if (syns) {
		for (var type in syns) {
			if (syns.hasOwnProperty(type)) {
				syns[type].forEach((word3) =>
				{
					if (!ignoreWord(word3)) {
						options.push(word3);
					}
				});
			}
		}
	}

	//// console.log("word synonyms", word, options);

	let i;

	for (i = 0; i < options.length; i++) {
		options[i] = natural.PorterStemmer.stem(options[i]);
	}

//	console.log("word roots", word, options);

	let selected = options[0];

	for (i = 1; i < options.length; i++) {
		if (options[i] < selected) selected = options[i];
	}

	ROOTS_CACHE[word] = selected;

	//// console.log("word main root", word, selected);

	return selected;
}


module.exports = getRoot;
