import React, {Component} from 'react';

import {
  STREAMS_LIST, STREAMS_DICT,
  IMAGES,
  SM_NONE,
  SM_QUERY,
  SM_ORG,
  SM_DOMAIN,
  SM_TAG,
} from '../../../constants/StreamDisplay';

import DesktopMenu from '../DesktopMenu/DesktopMenu';

import './PortraitMenu.scss';

class PortraitMenu extends Component {
  constructor() {
    super();
    this.state = {
      menuOpened: false,
    }

    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.selectStream = this.selectStream.bind(this);
    this.menuSwitch = this.menuSwitch.bind(this);
  }

  menuSwitch() {
    if (this.state.menuOpened) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
  }

  openMenu() {
    this.setState({
        menuOpened: true,
      }
    );
  }

  closeMenu() {
    this.setState({
        menuOpened: false,
      }
    );
  }

  selectStream(stream) {
    const _this = this;

    this.setState({
        menuOpened: false,
      },
      () => _this.props.selectStream(stream)
    );
  }


  render() {
    const _this = this;

    const iconSize = 28;

    // todo show desktop menu on click menu, show cross as icon

    return (
      <div className="NewsMenu" style={{zIndex: 100, background: "white", width: "100%"}}>

          <span className="Brand" onClick={() => _this.menuSwitch()}>
            <span className={`menu-icon icon dripicons-${_this.state.menuOpened ? 'cross' : 'menu'}`} />
            <span style={{position: "relative", top: -4}}>&nbsp;&nbsp;Offline<span className="Dot">&bull;</span>News</span>

            {[{stream:'all', icon: 'home', display:'Top stories'}].concat(STREAMS_LIST).filter(stream => (_this.props.stream_ === stream.stream)).map((stream) =>
              <React.Fragment>
              <span style={{float: "right", position: "relative", top: -4}}>
                {_this.props.showCurrentStream && <span style={{position: "relative", top: -4}}>{stream.display}&nbsp;</span>}
                <span key={stream.stream} style={{marginRight: 15, width: iconSize, height:iconSize, fontSize: iconSize}} onClick={() => _this.selectStream(stream.stream)} >
                  {stream.char && <span className={`menu-char`}>{stream.char}</span>}
                  {stream.icon && <span className={`menu-icon icon dripicons-${stream.icon}`}/>}
                  {(stream.imgActive || stream.img) && <img style={{verticalAlign: "unset"}} width={iconSize} height={iconSize} className={`menu-image menu-image-highlight`} src={IMAGES[_this.props.highlightStream_ === stream.stream ? stream.imgActive || stream.img : stream.img]}/>}
                </span>
              </span>
              </React.Fragment>
            )}
          </span>



          {_this.state.menuOpened &&
            <DesktopMenu
              searchMode_={_this.props.searchMode_}
              selectStream={_this.selectStream}
              getDisplaySearchTerm={_this.props.getDisplaySearchTerm}
              handleKeyDownInSearch={_this.props.handleKeyDownInSearch}
              highlightStream_={_this.props.highlightStream_}
              stream_={_this.props.stream_}
              width={_this.props.width}
              height={_this.props.height}
              showBranding={false}
           /> }
        </div>
      );
  }
}

export default PortraitMenu;
