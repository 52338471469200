import IStreamProvider from './IStreamProvider';
import logger from '../utils/logger';

// will route to best provider to use, used in browser
class SmartStreamProvider extends IStreamProvider {
  constructor(rest, indexdb) {
    super();

    this.rest = rest;
    this.indexdb = indexdb;
  }

  query(query, onSuccess, onError) {
    // if indexdb is up to date, use it
    let idxQueried = false;
    if (this.indexdb.ready()) {
      idxQueried = true;
      this.indexdb.query(query, onSuccess, onError);
      // todo return once ready is trully ready and all functions are implemented for idx
    }

    let answered = {rest: {success: false, fail: false}, indexdb: {success: false, fail: false}};

    // if indexdb not up to date, return indexdb or rest
    // if rest returned, then indexdb results are ignored
    // if indexdb returns first, then rest, rest will provided data one more time
    // hence, onSuccess might be called twice

    this.rest.query(query,
      (data) =>
      {
        answered.rest.success = true;
        onSuccess(data);
      },
      (err) =>
      {
        answered.rest.fail = true;
        // only return fail, if indexdb failed too, otherwise wait for indedb return
        if (answered.indexdb.fail) {
          onError(err);
        }
      },
    );

    if (!idxQueried) {
      // ok, get stale data from idx even if not ready
      this.indexdb.query(query,
        (data) =>
        {
          answered.indexdb.success = true;
          if (!answered.rest.success) {
            onSuccess(data);
          }
        },
        (err) =>
        {
          answered.indexdb.fail = true;
          if (answered.rest.fail) {
            onError(err);
          }
        },
      );
    }
  }
};

export default SmartStreamProvider;
