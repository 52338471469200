import DataTable from '../storage/DataTable';

import {BJ_FETCH_RSS} from '../constants/BackgroundJobTypes';

// todo how to use a service worker?
// todo for each rss, scan, index it, periodically
// show page with rss list, manage
// store news seen
// show list of news
// score news on user profile and user notes
// deploy to digital ocean
// test tablet, phone

// notes app

// shared search indexes, mesh ... ?
// integrate with p2p?

// todo move to service worker rss loader

// all in seconds, unless specified
const SPIDER_SETTINGS = {
  runEveryMs: 1000,
  checkActionAfter: 60,
  actionTimeout: 60 * 1,
};

class Spider {
  constructor(bjm) {
    const _this = this;
    this.registered = false;
    this.rssNewsSources = new DataTable("news-rss",
      () => {
        this.registered = true;
        this.rssNewsSources.getAll(
          jobs => {
            for (const job of jobs) {
             // console.log(job);
              // todo addMany with map
              bjm.addOne(
                {
                  "type": BJ_FETCH_RSS,
                  "args": job.url,
                  "repeatEverySeconds": 60 * 60,
                  "timeoutSeconds": 60 * 1,
                  "_id": BJ_FETCH_RSS + ':' + job.url,
                }
              )
            }
          },
          err => {
            console.error("error retrieving rss feeds", err);
          }
        );
      },
      (err) => {}
    );
  }
}

var __spider = null;

function getSpider(bjm) {
  __spider = new Spider(bjm);
  return __spider;
}

export default getSpider;
