import React, {Component} from 'react';
import DataTable from '../../storage/DataTable';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';

import Article from '../../components/Article/Article';
import SxSArticle from '../../components/SxSArticle/SxSArticle';

import './NewsPage.scss';

import {
  STREAMS_LIST, STREAMS_DICT,
  IMAGES,
  SM_NONE,
  SM_QUERY,
  SM_ORG,
  SM_DOMAIN,
  SM_TAG,
} from '../../constants/StreamDisplay';

import {getGlobal} from '../../storage/AppState';
import {setGlobal} from '../../storage/AppState';

import getRoot from '../../shared/nlp/getRoot';

import RestStreamProvider from '../../shared/streams/RestStreamProvider';
import IndexDbStreamProvider from '../../shared/streams/IndexDbStreamProvider';
import SmartStreamProvider from '../../shared/streams/SmartStreamProvider';

import DesktopMenu from '../../components/Menus/DesktopMenu/DesktopMenu';
// TODO no LandscapeMenu, using PortraitMenu
import LandscapeMenu from '../../components/Menus/PortraitMenu/PortraitMenu';
import PortraitMenu from '../../components/Menus/PortraitMenu/PortraitMenu';


// const sourcesLib = require('../../shared/sources/sources.js');

//import hotUtils from '../../shared/articles/getHotTopics';

function timeDiffSeconds(startDate, endDate) {
  return (endDate.getTime() - startDate.getTime()) / 1000;
}

function niceDate(date) {
  const now = new Date();
  let delta = Math.floor(timeDiffSeconds(new Date(date), now) / 60);

  if (delta < 5) return "now";
  if (delta < 60) return (5 * Math.floor(delta/5)) + " minutes ago";
  delta /= 60; delta = Math.floor(delta);
  if (delta < 24) return delta == 1 ? "one hour ago" : delta + " hours ago";
  delta /= 24; delta = Math.floor(delta);
  if (delta < 7) return delta == 1 ? "yesterday" : delta + " days ago";
  delta /= 7; delta = Math.floor(delta);
  if (delta < 4) return delta == 1 ? "last week" : delta + " weeks ago";
  delta /= 4; delta = Math.floor(delta);
  if (delta < 12) return delta == 1 ? "last month" : delta + " months ago";
  delta /= 3; delta = Math.floor(delta);
  return delta == 1 ? "last year" : delta + " years ago";
}

const WEATHER = {
  USA: { url: "https://forecast7.com/en/37d09n95d71/united-states/?unit=us", label: "UNITED STATES" },
  AL:{ url: "https://forecast7.com/en/32d32n86d90/alabama/?unit=us", label: "ALABAMA" },
  AK:{ url: "https://forecast7.com/en/64d20n149d49/alaska/?unit=us", label: "ALASKA" },
  AZ:{ url: "https://forecast7.com/en/34d05n111d09/arizona/?unit=us", label: "ARIZONA" },
  AR:{ url: "https://forecast7.com/en/35d20n91d83/arkansas/?unit=us", label: "ARKANSAS" },
  CA:{ url: "https://forecast7.com/en/36d78n119d42/california/?unit=us", label: "CALIFORNIA" },
  CO:{ url: "https://forecast7.com/en/39d55n105d78/colorado/?unit=us", label: "COLORADO" },
  CT:{ url: "https://forecast7.com/en/41d60n73d09/connecticut/?unit=us", label: "CONNECTICUT" },
  DE:{ url: "https://forecast7.com/en/38d91n75d53/delaware/?unit=us", label: "DELAWARE" },
  FL:{ url: "https://forecast7.com/en/27d66n81d52/florida/?unit=us", label: "FLORIDA" },
  GA:{ url: "https://forecast7.com/en/32d17n82d90/georgia/?unit=us", label: "GEORGIA" },
  HI:{ url: "https://forecast7.com/en/19d90n155d58/hawaii/?unit=us", label: "HAWAII" },
  ID:{ url: "https://forecast7.com/en/44d07n114d74/idaho/?unit=us", label: "IDAHO" },
  IL:{ url: "https://forecast7.com/en/40d63n89d40/illinois/?unit=us", label: "ILLINOIS" },
  IN:{ url: "https://forecast7.com/en/40d27n86d13/indiana/?unit=us", label: "INDIANA" },
  IA:{ url: "https://forecast7.com/en/41d88n93d10/iowa/?unit=us", label: "IOWA" },
  KS:{ url: "https://forecast7.com/en/39d01n98d48/kansas/?unit=us", label: "KANSAS" },
  KY:{ url: "https://forecast7.com/en/37d84n84d27/kentucky/?unit=us", label: "KENTUCKY" },
  LA:{ url: "https://forecast7.com/en/30d98n91d96/louisiana/?unit=us", label: "LOUISIANA" },
  ME:{ url: "https://forecast7.com/en/45d25n69d45/maine/?unit=us", label: "MAINE" },
  MD:{ url: "https://forecast7.com/en/39d05n76d64/maryland/?unit=us", label: "MARYLAND" },
  MA:{ url: "https://forecast7.com/en/42d41n71d38/massachusetts/?unit=us", label: "MASSACHUSETTS" },
  MI:{ url: "https://forecast7.com/en/44d31n85d60/michigan/?unit=us", label: "MICHIGAN" },
  MN:{ url: "https://forecast7.com/en/46d73n94d69/minnesota/?unit=us", label: "MINNESOTA" },
  MS:{ url: "https://forecast7.com/en/32d35n89d40/mississippi/?unit=us", label: "MISSISSIPPI" },
  MO:{ url: "https://forecast7.com/en/37d96n91d83/missouri/?unit=us", label: "MISSOURI" },
  MT:{ url: "https://forecast7.com/en/46d88n110d36/montana/?unit=us", label: "MONTANA" },
  NE:{ url: "https://forecast7.com/en/41d49n99d90/nebraska/?unit=us", label: "NEBRASKA" },
  NV:{ url: "https://forecast7.com/en/38d80n116d42/nevada/?unit=us", label: "NEVADA" },
  NH:{ url: "https://forecast7.com/en/43d19n71d57/new-hampshire/?unit=us", label: "NEW HAMPSHIRE" },
  NJ:{ url: "https://forecast7.com/en/40d06n74d41/new-jersey/?unit=us", label: "NEW JERSEY" },
  NM:{ url: "https://forecast7.com/en/34d52n105d87/new-mexico/?unit=us", label: "NEW MEXICO" },
  NY:{ url: "https://forecast7.com/en/43d30n74d22/new-york/?unit=us", label: "NEW YORK" },
  NC:{ url: "https://forecast7.com/en/35d76n79d02/north-carolina/?unit=us", label: "NORTH CAROLINA" },
  ND:{ url: "https://forecast7.com/en/47d55n101d00/north-dakota/?unit=us", label: "NORTH DAKOTA" },
  OH:{ url: "https://forecast7.com/en/40d42n82d91/ohio/?unit=us", label: "OHIO" },
  OK:{ url: "https://forecast7.com/en/35d01n97d09/oklahoma/?unit=us", label: "OKLAHOMA" },
  OR:{ url: "https://forecast7.com/en/43d80n120d55/oregon/?unit=us", label: "OREGON" },
  PA:{ url: "https://forecast7.com/en/41d20n77d19/pennsylvania/?unit=us", label: "PENNSYLVANIA" },
  RI:{ url: "https://forecast7.com/en/41d58n71d48/rhode-island/?unit=us", label: "RHODE ISLAND" },
  SC:{ url: "https://forecast7.com/en/33d84n81d16/south-carolina/?unit=us", label: "SOUTH CAROLINA" },
  SD:{ url: "https://forecast7.com/en/undefined/?unit=us", label: "SOUTH CAROLINA" },
  TN:{ url: "https://forecast7.com/en/35d52n86d58/tennessee/?unit=us", label: "TENNESSEE" },
  TX:{ url: "https://forecast7.com/en/31d97n99d90/texas/?unit=us", label: "TEXAS" },
  UT:{ url: "https://forecast7.com/en/39d32n111d09/utah/?unit=us", label: "UTAH" },
  VT:{ url: "https://forecast7.com/en/44d56n72d58/vermont/?unit=us", label: "VERMONT" },
  VA:{ url: "https://forecast7.com/en/37d43n78d66/virginia/?unit=us", label: "VIRGINIA" },
  WA:{ url: "https://forecast7.com/en/47d75n120d74/washington/?unit=us", label: "WASHINGTON" },
  DC:{ url: "https://forecast7.com/en/38d91n77d04/washington/?unit=us", label: "WASHINGTON D.C." },
  WV:{ url: "https://forecast7.com/en/38d60n80d45/west-virginia/?unit=us", label: "WEST VIRGINIA" },
  WI:{ url: "https://forecast7.com/en/43d78n88d79/wisconsin/?unit=us", label: "WISCONSIN" },
  WY:{ url: "https://forecast7.com/en/43d08n107d29/wyoming/?unit=us", label: "WYOMING" },
}


function getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = decodeURI(value);
    });
    return vars;
}


class NewsPage extends Component {
  // <Route path="/about" component={About} />
  constructor() {
    super();
    this.state = {
      dictArticles: {},
      allArticles: [],
      articles: [],
      websites: {},
      streamStories: {},
      hotTopics: {},
      stream: '',
      stream_: '',
      searchMode: SM_NONE,
      searchMode_: SM_NONE,
      query: '',
      queryRoot: '',
      searchTag: '',
      searchOrg: '',
      searchDomain: '',
      // todo by state since the links are hardcoded
      // generate 50 links
      weatherLocation: '',
      highlightStream: 'all',
      highlightStream_: 'all',
    };

    this.selectStream = this.selectStream.bind(this);
    this.searchFor = this.searchFor.bind(this);
    this.handleKeyDownInSearch = this.handleKeyDownInSearch.bind(this);
    this.searchOrgOrDomain = this.searchOrgOrDomain.bind(this);
    this.searchTag = this.searchTag.bind(this);
    this.getDisplaySearchTerm = this.getDisplaySearchTerm.bind(this);
    this.highlightStream = this.highlightStream.bind(this);
    this.loadInitialState = this.loadInitialState.bind(this);
    this.setWebsitesState = this.setWebsitesState.bind(this);

    this.providerRest = new RestStreamProvider();
    this.providerIdb = new IndexDbStreamProvider();
    this.provider = new SmartStreamProvider(this.providerRest, this.providerIdb);
  }

  highlightStream(stream) {
    if (stream && this.state.highlightStream !== stream && this.state.stream === 'all') {
      this.setState({highlightStream: stream});
    }
  }

  query(query, state, quickState) {
    state = state || {};

    const _this = this;

    // this should quickly select menu on the left
    _this.setState(quickState);

    this.provider.query(query,
    res => {
      console.log("query results", query, res);

      let articles = [];

      if (query.top) {
        ([{stream: 'all'}].concat(STREAMS_LIST)).map((stream) => {
          let section = res.articles[stream.stream];
          if (!section) return;
          // realtime and SxS must be clicked manually, otherwise scroll experience is confusing
          if (STREAMS_DICT[stream.stream] && STREAMS_DICT[stream.stream].mustClick) return;

          articles.push({_section: stream.stream});  // empty article that shows "Explore Foo" heading

          for (let i = 0; i < section.length; i++) {
            section[i].highlightStream = stream.stream;
            section[i]._stream = stream.stream;
          }
          articles = articles.concat(section);
        });
      } else {
        articles = res;
      }

      // Avoid double refresh, set the state imediately with url params
      _this.setState({...state, articles: articles, allArticles: articles, dictArticles: res.articles, hotTopics: {} /*res.topics || this.state.hotTopics*/, loading: false });

      // TODO show here that we got new results? if in lazy more? add param show ?

      window.scrollTo(0, 0);
    },
    err => {
      console.log("query failed", query, err);
    });

  }

  selectStream(stream) {
    if (!stream) return;

    if (stream === 'all') {
      this.props.history.push('');
    } else {
      this.props.history.push('?stream=' + stream);
    }

    this.query({top: stream === 'all', stream: stream, topArticles: 5, topKeywords: 10},
               {stream: stream, highlightStream: stream, searchMode: SM_NONE},
               {stream_: stream, highlightStream_: stream, searchMode_: SM_NONE}
             );
  }

  searchFor(query, state) {
    if (!state) {
      this.props.history.push('?search=' + encodeURI(query));
    }
    state = state || this.state;
    query = query.trim().toLowerCase();
    this.setState({...state, stream: 'all', searchMode: SM_QUERY, query: query, queryRoot: getRoot(query), articles: state.allArticles});
    window.scrollTo(0, 0);
  }

  searchOrgOrDomain(org, domain, state) {
    if (org) {
      if (!state) {
        this.props.history.push('?org=' + org);
      }
      state = state || this.state;
      this.setState({...state, stream: 'all', searchMode: SM_ORG, org: org, articles: state.allArticles});
      window.scrollTo(0, 0);
    } else {
      if (!state) {
        this.props.history.push('?site=' + domain);
      }
      state = state || this.state;
      this.setState({...state, stream: 'all', searchMode: SM_DOMAIN, domain: domain, articles: state.allArticles});
      window.scrollTo(0, 0);
    }
  }

  searchTag(tag, state) {
    if (!state) {
      this.props.history.push('?tag=' + encodeURI(tag));
    }
    state = state || this.state;
    this.setState({...state, stream: 'all', searchMode: SM_TAG, tag: tag});
    window.scrollTo(0, 0);
  }

  getDisplaySearchTerm() {
    if (this.state.searchMode === SM_TAG) return `tag:\xa0${this.state.tag}`;
    if (this.state.searchMode === SM_ORG) return `org:\xa0${this.state.org}`;
    if (this.state.searchMode === SM_DOMAIN) return `site:\xa0${this.state.domain}`;
    if (this.state.searchMode === SM_QUERY) return this.state.query;
    return '';
  }

  handleKeyDownInSearch(e) {
    if (e.key === 'Enter') {
      this.searchFor(e.target.value.trim().split(' ')[0]);
    }
  }

  // ideally we sould get details from route parameters, but I had issues
  // doing multiple routes without causing re-rerender
  loadInitialState() {
    const args = getUrlVars();

    if (args['stream']) {
      this.selectStream(args['stream']);
    } else if (args['org'] || args['site']) {
      this.searchOrgOrDomain(args['org'], args['site']);
    } else if (args['tag']) {
      this.searchTag(args['tag']);
    } else if (args['search']) {
      this.searchFor(args['search']);
    } else {
      this.selectStream('all');
    }
  }

  // todo, move in props updated
  componentDidMount() {
    const _this = this;

    this.loadInitialState();
//    this.query({top: true, topArticles: 5, topKeywords: 10});

    let location = getGlobal('usa-region');
    if (location) {
      _this.setState({weatherLocation: location});
    } else {
      fetch('https://ipapi.co/json', {cache: 'no-cache', redirect: 'follow'})   // does not work in brave
      .then(res => {
        return res.json()
      })
      .then(res => {
        const region = (res.region_code || '').toUpperCase();
        if (region && res.country && res.country.toUpperCase() === 'US' && WEATHER[region]) {
          _this.setState({weatherLocation: region});
          setGlobal('usa-region', region);
        } else {
          console.error('Could find mapping for location:', res);
          setGlobal('usa-region', 'USA');
          _this.setState({weatherLocation: 'USA'});
        }
      })
      .catch(error => {
        setGlobal('usa-region', 'USA');
        _this.setState({weatherLocation: 'USA'});
        console.error('Could not load user location:', error);
      });
    }

    // have top to return wesites? have a param?
    // or only show hardcoded favicon, and wait for rerender to show it ...
    // have websites.syncNow() ... that will invoke api call also

// todo use ... it will work on new page
//    this.provider.query(query,


    // TODO have this login copied into the query
    // to first get from indexdb, then from API
    // and automatically call delta with force show results
    // if new results from API
    // later new deltas will ask user for input
    // test in incognito window, or with new database
    // todo ... this in last item ....
    let websites = new DataTable("website",
      () => {
        websites.getAll(
          websites_list => {
            if (websites_list && websites_list.length > 0) {
              console.error("unsing cached websites");
              _this.setWebsitesState(websites_list);
            } else {
              console.error("unsing rest websites");
              _this.providerRest.query({stream: 'domains'},
                websites_list => {
                  console.log("got rest websites");
                  _this.setWebsitesState(websites_list);
                },
                err => {
                  console.error("error retrieving websites", err);
                }
              );
            }
          },
          err => {
            console.error("trying rest websites 2");
            _this.providerRest.query({stream: 'domains'},
              websites_list => {
                console.log("unsing log websites 2");
                _this.setWebsitesState(websites_list);
              },
              err => {
                console.error("error retrieving websites", err);
              }
            );
          }
        );
      },
      (err) => {
        console.error("trying rest websites 3");
        _this.providerRest.query({stream: 'domains'},
          websites_list => {
            console.log("unsing rest websites 3");
            _this.setWebsitesState(websites_list);
          },
          err => {
            console.error("error retrieving websites", err);
          }
        );
      }
    );
  }

  setWebsitesState(websites_list) {
    const _this = this;

    console.log("domains", websites_list);

    let websites = {};
   // console.log("websites_list", websites_list);
    websites_list.forEach((ws) => {
        if (ws.faviconBytes && ws.faviconBytes.startsWith('data:image/text')) {
          ws.faviconBytes = null;
        }
        websites[ws.domain] = ws;
    });

    _this.setState({websites: websites});

  }

  componentDidMountOld() {
    const _this = this;

    // todo .. this needs to be pushed from worker
    if (false) {
      let articles = new DataTable("news-article",
        () => {
          articles.getAll(
            articles => {
              // todo, call server side, or on worker only!
              //let foo = hotUtils.getHotArticles(new Date(), articles, 5, 20);
              //_this.setState({articles: foo['all'], hotTopics: hotUtils.getHotTopics(articles, 10)});
              _this.setState({articles: articles}); //hotTopics: hotUtils.getHotTopics(articles, 10)});
            },
            err => {
              console.error("error retrieving articles", err);
            },
            undefined
          );
        },
        (err) => {}
      );


    let stories = new DataTable("story",
      () => {
        stories.getAll(
          stories_list => {
            let streams = {};
//           // console.log("stories_list", stories_list);

            stories_list.forEach((s) => {
              if (!streams[s.stream]) streams[s.stream] = [];

              streams[s.stream].push(s);
            });


            // Object.keys(streams).forEach((stream) => {
            //   streams[stream].sort(function(a, b){return a.dateReceived - b.dateReceived;})
            // });


            _this.setState({streamStories: streams});
          },
          err => {
            console.error("error retrieving websites", err);
          }
        );
      },
      (err) => {}
    );
  }

  }

  render() {
    const _this = this;
    console.log("news page state", _this.state);
   // console.log("news page props", _this.props);

    const query = this.state.query;

    function sortByPublished(a, b) { return new Date(b.datePublished).getTime() - new Date(a.datePublished).getTime(); }
    function sortByReceived(a, b) { return b.dateReceived - a.dateReceived; }
    function sortByCounts(a, b) { return b._bog.counts[query] - a._bog.counts[query]; }

    const sorter = query ? sortByCounts : sortByReceived;

    const filterQuery = (article) => article._bog.counts[_this.state.queryRoot];
    const filterOrg = (article) => _this.state.org === article.org;
    const filterDomain = (article) => _this.state.domain === article.domain;
    const filterStream = (article) => true; // _this.state.stream === article._stream;  // can be realtime also
    const filterTag = (article) => article.tags && article.tags.includes(_this.state.tag);

    let filter = (article) => true;
    if (_this.state.stream !== 'all') filter = filterStream;
    else if (_this.state.searchMode === SM_TAG) filter = filterTag;
    else if (_this.state.searchMode === SM_ORG) filter = filterOrg;
    else if (_this.state.searchMode === SM_DOMAIN) filter = filterDomain;
    else if (_this.state.searchMode === SM_QUERY) filter = filterQuery;

//           <div>{article.story}</div>

    let displayedAlready = {};
    function skipArticle(article) {
// since we have a sincle array, it contains the top articles twice
//      if (_this.state.stream !== 'all') return false;
      if (displayedAlready[article.title]) {
        return true;
      }
      displayedAlready[article.title] = true;
      return false;
    }

    const screenDimensionClass = this.props.screenSize === 'Small' ? 'small-screeen' : 'large-screen';

    const isSmallScreen = this.props.screenSize === 'Small';
    const isLandscape = this.props.isLandscape;

    return (
      <div className="NewsPage">

      {!isSmallScreen &&
        <div className="Weather">
        {_this.state.weatherLocation &&
        <a className="weatherwidget-io" href={WEATHER[_this.state.weatherLocation].url} data-label_1={WEATHER[_this.state.weatherLocation].label} data-days="7" data-theme="clear" ></a>
        }
      </div>}

      {!isSmallScreen && (this.state.hotTopics[this.state.highlightStream || this.state.stream]) && <div className="Trending">
        <h6>Hot Topics</h6>
        <ul>
        {(this.state.hotTopics[this.state.highlightStream || this.state.stream] || []).map((topic) =>
          <li key={topic.word} className="Term" onClick={() => _this.searchFor(topic.word)}>{topic.word}</li>
        )}
        </ul>
      </div>}

      {!isSmallScreen && <DesktopMenu
                            searchMode_={_this.state.searchMode_}
                            selectStream={_this.selectStream}
                            getDisplaySearchTerm={_this.getDisplaySearchTerm}
                            handleKeyDownInSearch={_this.handleKeyDownInSearch}
                            highlightStream_={_this.state.highlightStream_}
                            stream_={_this.state.stream_}
                            width={_this.props.width}
                            height={_this.props.height}
                            showBranding={true}
                         /> }

      {isSmallScreen && isLandscape &&
        <LandscapeMenu
            searchMode_={_this.state.searchMode_}
            selectStream={_this.selectStream}
            getDisplaySearchTerm={_this.getDisplaySearchTerm}
            handleKeyDownInSearch={_this.handleKeyDownInSearch}
            highlightStream_={_this.state.highlightStream_}
            stream_={_this.state.stream_}
            width={_this.props.width}
            height={_this.props.height}
            showCurrentStream={true}
         />
      }

      {isSmallScreen && !isLandscape &&
        <PortraitMenu
            searchMode_={_this.state.searchMode_}
            selectStream={_this.selectStream}
            getDisplaySearchTerm={_this.getDisplaySearchTerm}
            handleKeyDownInSearch={_this.handleKeyDownInSearch}
            highlightStream_={_this.state.highlightStream_}
            stream_={_this.state.stream_}
            width={_this.props.width}
            height={_this.props.height}
            showCurrentStream={false}
         />
      }

        <ul className={`NewsContent${isSmallScreen ? 'Small' : ''}`}>
        {this.state.articles.filter(filter)
                            //.sort(sorter)
                            // .slice(0, 20)
                            .map((article, index) => (
          <React.Fragment>
          {article._section && article._section !== 'all' &&
          <li key={`section-${article._section}`}>
            <div className="SectionContainer">
              <span className="SectionHeader">
                {STREAMS_DICT[article._section] &&
                  <span className="SectionExplore">Explore </span>
                }
                <span className={`${article._section === 'all' ? 'SectionAll' : 'SectionTitle'}`}  onClick={() => _this.selectStream(article._section === 'all' ? '' : article._section)}>{(STREAMS_DICT[article._section] || {}).display || 'Top stories'}</span>
              </span>
            </div>
          </li>}
          {!article._section && _this.state.stream !== 'sxs' && !skipArticle(article) && <li key={_this.state.stream + '-' + article.stream + '-' + article.guid + '-' + index} className="ArticlePreview" onMouseEnter={() => _this.highlightStream(article.highlightStream)}>
            <Article
                article={article}
                stream={_this.state.stream}
                websites={_this.state.websites}
                stories={_this.state.stories}
                selectStream={_this.selectStream}
                searchOrgOrDomain={_this.searchOrgOrDomain}
                searchTag={_this.searchTag}
                small={isSmallScreen}
                isLandscape={isLandscape}
            />
          </li>}
          {!article._section && _this.state.stream === 'sxs' && <li key={_this.state.stream + '-' + article.stream + '-' + article.guid + '-' + index} className="ArticlePreview" onMouseEnter={() => _this.highlightStream(article.highlightStream)}>
            <SxSArticle
                article={article}
                stream={_this.state.stream}
                websites={_this.state.websites}
                stories={_this.state.stories}
                selectStream={_this.selectStream}
                searchOrgOrDomain={_this.searchOrgOrDomain}
                searchTag={_this.searchTag}
                small={isSmallScreen}
                isLandscape={isLandscape}
            />
          </li>}
          </React.Fragment>
        ))}
        </ul>

        {!isSmallScreen && <div className="SiteDetails">
          <div className="Contact">
            Contact/Feedback: <a href="mailto:hostmaster@offline.news" target="_blank">hostmaster@offline.news</a>
          </div>
          <div className="Credits">
            Credits: <a href="https://github.com/amitjakhu/dripicons" target="_blank">Dripicons</a>
          </div>
          <div className="Copyright">&copy; Copyright 2019 Offline.News</div>
        </div>}

      </div>
    );
  }
}

export default NewsPage;
