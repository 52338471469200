import React, {Component} from 'react';
import Article from '../Article/Article';

import './SxSArticle.scss';

class SxSArticle extends Component {
  constructor() {
    super();
  }

  render() {
    const _this = this;
    return (
      <div className="SxSArticle">
        <Article
          article={_this.props.article}
          stream={_this.props.stream}
          websites={_this.props.websites}
          stories={_this.props.stories}
          selectStream={_this.props.selectStream}
          searchOrgOrDomain={_this.props.searchOrgOrDomain}
          searchTag={_this.props.searchTag}
          small={_this.props.small}
          isLandscape={_this.props.isLandscape}
          bg="#e4f4ff"
        />

        <Article
          article={_this.props.article.neg}
          stream={_this.props.stream}
          websites={_this.props.websites}
          stories={_this.props.stories}
          selectStream={_this.props.selectStream}
          searchOrgOrDomain={_this.props.searchOrgOrDomain}
          searchTag={_this.props.searchTag}
          small={_this.props.small}
          isLandscape={_this.props.isLandscape}
          bg="#fffce1"
        />

      </div>
    );
  }
}

export default SxSArticle;
