import createBackgroundJob from './BackgroundJobFactory';
import DataTable from '../storage/DataTable';

const BJ_SETTINGS = {
  maxConcurency: 10,
  defaultJobTimeout: 60,
  beatEverySeconds: 180,
  readyWaitMs: 100,
};

// Implement Worker for the Background Jobs to run in background
// and notify of state updates, progress, new data, notifications

class BackgroundJobManager {
  constructor(onSuccess, onError, di) {
    const _this = this;
    this.di = di;
    this.backgroundJobs = new DataTable("background-job",
      () => {
        onSuccess();
        _this._start();
      },
      onError
    );
    this.paused = true;
    this.runningJobs = [];
  }

  _start() {
    if (this.paused) {
      this.paused = false;
      setTimeout(this.heartBeat, 0);
    }
  }

  heartBeat() {
    console.log("Hearthbeat")
    const _this = __backgroundJobManager;

    if (!_this || !_this.backgroundJobs.ready || !__backgroundJobManager) {
      setTimeout(_this.heartBeat, BJ_SETTINGS.readyWaitMs);
      return;
    }

    if (_this.paused) return;
    // TODO Basic implementation for now, run all jobs
    // remove for completed jobs, update their last run
    // cancel the ones that outlived their timeout
    // if available space/concurency
      // fetch all bjs
      // schedule new ones

    _this.backgroundJobs.getAll(
      jobs => {
        for (let i = 0; i < jobs.length; i++) {
          const jobDef = jobs[i];
         // console.log(jobDef);
          let job = createBackgroundJob(jobDef.type);
          job.startJob(jobDef.args, _this.di);
        }
      },
      err => {
        console.error("error retrieving rss feeds", err);
      }
    );

    setTimeout(_this.heartBeat, BJ_SETTINGS.beatEverySeconds * 1000);
  }

  pause() {
    this.paused = true;
  }

  add(job) {
    const _this = __backgroundJobManager;
    _this.backgroundJobs.addOne(job);
  }
}

// todo promises would solve the problem if this singleton object is wanted from
// multiple places, and each caller has it's own success/failure callbacks?
var __backgroundJobManager = null;

function getBackgroundJobManager(onSuccess, onError, di) {
  __backgroundJobManager = new BackgroundJobManager(onSuccess, onError, di);
  return __backgroundJobManager;
}

export default getBackgroundJobManager;
