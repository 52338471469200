import ConsoleLogBackgroundJob from './ConsoleLogBackgroundJob';
import FetchRssBackgroundJob from './FetchRssBackgroundJob';
import FetchNewsBackgroundJob from './FetchNewsBackgroundJob';
import {BJ_FETCH_RSS, BJ_FETCH_NEWS, BJ_CONSOLE_LOG} from '../constants/BackgroundJobTypes';

function createBackgroundJob(type) {
  switch (type) {
    case BJ_CONSOLE_LOG:
      return new ConsoleLogBackgroundJob();
    case BJ_FETCH_RSS:
      return new FetchRssBackgroundJob();
    case BJ_FETCH_NEWS:
      return new FetchNewsBackgroundJob();
    default:
      return null;
  }
}

export default createBackgroundJob;
