const WORDS_TO_IGNORE_LIST = [
  "making",
  "photo",
  "gallery",
  "photos",

  "about",
  "after",
  "again",
  "against",
  "also",
  "another",
  "audio",
  "back",
  "because",
  "become",
  "been",
  "begin",
  "between",
  "call",
  "case",
  "come",
  "company",
  "could",
  "down",
  "during",
  "each",
  "even",
  "every",
  "feel",
  "find",
  "first",
  "from",
  "give",
  "good",
  "great",
  "hand",
  "have",
  "hear",
  "here",
  "high",
  "hits",
  "into",
  "just",
  "keep",
  "know",
  "last",
  "leave",
  "life",
  "like",
  "look",
  "make",
  "man",
  "many",
  "may",
  "mean",
  "might",
  "more",
  "more",
  "most",
  "most",
  "much",
  "need",
  "never",
  "news",
  "only",
  "other",
  "over",
  "over",
  "own",
  "part",
  "people",
  "place",
  "play",
  "program",
  "question",
  "really",
  "right",
  "same",
  "seem",
  "should",
  "show",
  "some",
  "something",
  "start",
  "state",
  "still",
  "such",
  "take",
  "tell",
  "than",
  "that",
  "that",
  "their",
  "them",
  "then",
  "there",
  "there",
  "these",
  "they",
  "thing",
  "think",
  "this",
  "those",
  "three",
  "through",
  "time",
  "turn",
  "two",
  "very",
  "video",
  "want",
  "week",
  "well",
  "what",
  "when",
  "where",
  "which",
  "while",
  "with",
  "work",
  "work",
  "would",
  "year",
  "your",

  "together",
  "further",
  "away",
  "whole",
  "quite",
  "quiet",
  "ixed",
  "ended",
  "long",
  "mostly",
  "higher",
  "greater",
  "rose",
  "rise",
  "deal",
  "fell",
  "jump",
  "percent",
  "makes",
  "within",
  "area",
  "near",
  "added",
  "been",
  "about",
  "since",
  "last",
  "lastly",
  "says",
  "said",
  "always",
  "meet",
  "update",
  // maybe just ignore from top topics only these
  "million",
  "billion",
  "linked",
  "before",
  "after",
  "review",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
  "buying",
  "selling",
  "medical",
  "health",
  "change",
  "thing",
  "things",
  "faster",
  "slower",
  "method",
  "product",
  "products",
  "weekend",
  "near",
  "nears",
];

const WORDS_WHITELIST_LIST = [
  "fbi",
  "cia",
  "gov",
  "usa",
  "dna",
  "ads",
  "ban",
  "dad",
  "fli",
  "git",
  "god",
  "dog",
  "gym",
  "ham",
  "ice",
  "ire",
  "ore",
  "leg",
  "ape",
  "van",
  "war",
  "wow",
  "zoo",
  "toy",
  "ski",
  "sky",
  "sow",
  "soy",
  "sex",
  "rug",
  "roe",
  "rob",
  "rap",
  "ram",
  "pie",
  "pad",
  "owl",
  "nun",
  "mom",
  "lax",
  "kit",
  "kin",
  "kid",
  "jar",
  "ivy",
  "ire",
  "hog",
  "hip",
  "ham",
  "gum",
  "gun",
  "gas",
  "fog",
  "flu",
  "fir",
  "fob",
  "fad",
  "eye",
  "eon",
  "egg",
  "die",
  "cat",
  "boa",
  "bar",
  "bat",
  "bee",
  "bag",
  "ash",
  "aid",
  "ale",
  "tsa",
  "nfl"
];

function arrayToSet(keys) {
  let tmp = {};
  keys.forEach(function (k, i) {
    tmp[k] = true;
  })
  return tmp;
}

const WORDS_TO_IGNORE_SET = arrayToSet(WORDS_TO_IGNORE_LIST);
const WORDS_WHITELIST_SET = arrayToSet(WORDS_WHITELIST_LIST);

function ignoreWord(word) {
  if (!word) return true;
  word = word.trim().toLowerCase();

  if (WORDS_WHITELIST_SET[word]) return false;

  if (word.length <= 3) return true;

  return !!WORDS_TO_IGNORE_SET[word];
}

module.exports = ignoreWord;
