// import fetch from 'node-fetch';
import DataTable from '../storage/DataTable';
import {getLastNewsCheckpoint} from '../storage/AppState';
import {setLastNewsCheckpoint} from '../storage/AppState';

// const API_LATEST_NEWS = "//offline.news/latest/{stream}/{since}";
const API_LATEST_NEWS = "/latest/{stream}/{since}";

// todo  reuse new method ... though there are not too many

class RoseWebsitesDelta {
  constructor(di) {
    const stream = 'domains';
    const _this = this;

    let url = API_LATEST_NEWS;
    url = url.replace("{stream}", stream);
    url = url.replace("{since}", getLastNewsCheckpoint(stream));

    const actionId = di.createAction('low', 'Loading Websites', '');

  let websitesStorage = new DataTable("website",
    () => {
      fetch(url)
      .then(res => {
        return res.json()
      })
      .then(res => {
        let items = res.items;

        if (!items) return;

        let remaining = items.length;

        items.forEach((item, index) => {

          const f = () => {
            remaining--;
            //console.log(remaining);

            di.actionProgress(actionId, "Loading ...", items.length - remaining, items.length);

            if (remaining === 0) {
              di.actionCompleted(actionId, items.length + ' websites Received, Enjoy!');

              setLastNewsCheckpoint(stream, res.checkpoint);

              if (items.length > 0) {
                di.forceRefreshApp();
              }
            }
          };

          websitesStorage.addOne(item, f, f);

          // todo ... break in smaller sets with set timeout ...
          // or move to worker

          //console.log("Import news progress", index, items.length);

        });

      });
    },
    (err) => {}
    );
  }

}

export default RoseWebsitesDelta;
