import React, {Component} from 'react';
import DataTable from '../../storage/DataTable';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';

import ArticleHeadline from '../ArticleHeadline/ArticleHeadline';


import './Article.scss';

import {STREAMS_LIST, STREAMS_DICT} from '../../constants/StreamDisplay';


function timeDiffSeconds(startDate, endDate) {
  return (endDate.getTime() - startDate.getTime()) / 1000;
}

function niceDate(date) {
  const now = new Date();
  let delta = Math.floor(timeDiffSeconds(new Date(date), now) / 60);

  if (delta < 5) return "now";
  if (delta < 60) return (5 * Math.floor(delta/5)) + " minutes ago";
  delta /= 60; delta = Math.floor(delta);
  if (delta < 24) return delta == 1 ? "one hour ago" : delta + " hours ago";
  delta /= 24; delta = Math.floor(delta);
  if (delta < 7) return delta == 1 ? "yesterday" : delta + " days ago";
  delta /= 7; delta = Math.floor(delta);
  if (delta < 4) return delta == 1 ? "last week" : delta + " weeks ago";
  delta /= 4; delta = Math.floor(delta);
  if (delta < 12) return delta == 1 ? "last month" : delta + " months ago";
  delta /= 3; delta = Math.floor(delta);
  return delta == 1 ? "last year" : delta + " years ago";
}




class Article extends Component {
  constructor() {
    super();
  }

  render() {
    const _this = this;
    const article = this.props.article;
    const websites = this.props.websites;
    const stories = this.props.stories;
    const stream = this.props.stream;

    let orgs = {};
    let cnt = 0;
    function canShow(org) {
      if (orgs[org] || cnt >= 3) return false;
      orgs[org] = true;
      cnt++;
      return true;
    }

    let snippet = article.contentSnippet || '';
    if (snippet.length > 160) {
      let pos = 160;
      while (pos >= 0 && snippet.charAt(pos) != ' ') pos--;
      snippet = snippet.substr(0, pos) + '...';
    }

    const small = this.props.small && !this.props.isLandscape;
    // todo ... if landscape, and small, image 40%
    // title main, put image absoute, then title with 5? nbsp in fron

    return (
      <Card border="info" style={{ width: '100%', backgroundColor: this.props.bg }}>
        <p className="ArticleTitleContainer">
          <img className={small ? 'Small' : 'NotSmall'} src={(websites[article.domain] || {}).faviconBytes} style={{ height: 20, width: null, marginRight: 10 }}/>
          <a className={`ArticleTitle${(small ? 'Small' : '')} ArticleAction`} target="_blank" href={article.link}>{article.title}</a>
        </p>
        <Card.Body className="ArticleText">
          <div className={`MainImageParent${(small ? 'Small' : '')}`}>
            <Image className="MainImage" src={article.org === 'Seeking Alpha' ? null : article.imageUrl}/>
          </div>
          {snippet}

          {(article.stories || []).filter((sub) => sub.org != article.org && canShow(sub.org)).map((sub) => (
            <ArticleHeadline
                article={sub}
                stream={_this.props.stream}
                websites={_this.props.websites}
                stories={_this.props.stories}
                selectStream={_this.props.selectStream}
                searchOrgOrDomain={_this.props.searchOrgOrDomain}
                searchTag={_this.props.searchTag}
                small={_this.props.small}
            />
          ))}

        </Card.Body>
        <Card.Footer style={{maxHeight: "2.7em", overflow: "hidden", paddingTop: 8}}>
          <span className="Publisher ArticleAction">
            <span onClick={() => _this.props.searchOrgOrDomain(article.org, article.domain)}>{article.org || (websites[article.domain] || {}).name}</span>
          </span>
          {!small &&
            <React.Fragment>
              &nbsp;&middot;&nbsp;
              <span className="PublishedDate">
                {niceDate(article.datePublished)}
              </span>
            </React.Fragment>
          }
          <span style={{float: "right"}}>
            {!small && (stream === 'all' || stream === 'realtime') &&
            <span className="ArticleAction Stream" onClick={() => _this.props.selectStream(article.stream)}>
              {STREAMS_DICT[article.stream].display}
            </span>
            }
            {!small && !(stream === 'all' || stream === 'realtime') &&
            <span>
            {(article.tags || []).length > 0 &&
              <span className="ArticleAction Tag" onClick={() => _this.props.searchTag(article.tags[0])}>
                {article.tags[0]}
              </span>
            }
            </span>}
            {small &&
              <React.Fragment>
                <span className="PublishedDate">
                  {niceDate(article.datePublished)}
                </span>
              </React.Fragment>
            }
          </span>
        </Card.Footer>
      </Card>

    );
  }
}

export default Article;
