// articles are in the order we want them displayed
// grouped by story
// no duplicated from same org

function buildStoryLine(articles, max) {
  articles = articles || [];
  let ret = [];

  let indexes = {};

  let i = 0;
  for (i = 0; i < articles.length; i++) {
    const article = articles[i];

    if (!indexes[article.story]) {
      let seed = {
        index: ret.length,
        orgs: {
        }
      };
      seed.orgs[article.org] = true; // do not show multiple articles on same story from same org
      indexes[article.story] = seed;
      ret.push(article);
      article.stories = [];
    }
    else
    {
      const main = ret[indexes[article.story].index];
      if (main.stories.length < max) {
        if (!indexes[article.story].orgs[article.org]) {
          indexes[article.story].orgs[article.org] = true;
          main.stories.push(article);
        }
      }
    }
  }
  // filter articles without story lines, or those with less than 20 chars in description (a few words)
  return ret.filter(a => a.stories.length > 0 || (a.contentSnippet && a.contentSnippet.length > 20));
}

module.exports = {
  buildStoryLine: buildStoryLine
};
