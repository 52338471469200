import React, {Component} from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";

import 'dripicons/webfont/webfont.css';

import getSpider from './crawler/Spider';

// TODO fix ie11 issues
import getBackgroundJobManager from './background/BackgroundJobManager';

import NewsPage from './pages/NewsPage/NewsPage';

import './App.scss';


function isMobileDevice() {
  return (typeof window.orientation !== "undefined") ||
         (navigator.userAgent.indexOf('IEMobile') !== -1) ||
         window.innerWidth <= 800;
};

function isLandscape() {
  return window.innerWidth >= window.innerHeight;
};

function getWidth() {
  return window.innerWidth;
}

function getHeight() {
  return window.innerHeight;
}

function getScreenSize() {
  if (isMobileDevice()) return 'Small';
  if (getWidth() < 1200) return 'Medium';
  return 'Large';
}

class App extends Component {
  constructor() {
    super();

    const __this = this;

    let stats = {
      lastActionId: 0,
      streamLoading: {},
      streamPending: {},
      streamCompleted: {},
    }

    __this.state = {
      refresh: 0,
      stats: stats,
      width: getWidth(),
      height: getHeight(),
      hasKeyboard: getScreenSize() === 'Large',
      screenSize: getScreenSize(),
      isLandscape: isLandscape(),
    };


    var bjManager = getBackgroundJobManager(
      () => {
        getSpider(bjManager);
      },
      () => {},
      {

        wipStream: function(stream) {
          return stats.streamLoading[stream];
        },

        receivedStreamData: function(stream, articles) {
          stats.streamLoading[stream] = true;
          stats.streamPending[stream] = articles.length;
          stats.streamCompleted[stream] = 0;

         // console.log('receivedData', stream, articles);
           __this.setState({refresh: __this.state.refresh + 1, stats: stats});
          // __this.forceUpdate();
        },

        savedData: function(stream, articlesSaved) {
          stats.streamCompleted[stream] = articlesSaved;
         // console.log('savedData', stream, articlesSaved);
          __this.setState({refresh: __this.state.refresh + 1, stats: stats});
          // __this.forceUpdate();
        },

        finishedSavingData: function(stream, count) {
          stats.streamLoading[stream] = false;
         // console.log('finishedSavingData', stream, count);
          __this.setState({refresh: __this.state.refresh + 1, stats: stats});
          // __this.forceUpdate();
        },

        refreshApp: function() {
         // console.log('refreshApp');
          __this.setState({refresh: __this.state.refresh + 1, stats: stats});
          // __this.forceUpdate();
        },
        forceRefreshApp: function() {
         // console.log('forceRefreshApp');
          __this.setState({refresh: __this.state.refresh + 1, stats: stats});
          // __this.forceUpdate();
          //window.location.reload();
        },
        createAction(type, title, message) {
         // console.log('createAction', type, title, message);
          return ++stats.lastActionId;
        },
        actionProgress(id, status, current, max) {
         // console.log('actionProgress', id, status, current, max);
          __this.setState({refresh: __this.state.refresh + 1, stats: stats});

        },
        actionCompleted(id, finalWords) {
         // console.log('actionCompleted', id, finalWords);
          __this.setState({refresh: __this.state.refresh + 1, stats: stats});

        },
        pushNotification(type, title, message, sticky) {
         // console.log('pushNotification', type, title, message, sticky);
          __this.setState({refresh: __this.state.refresh + 1, stats: stats});
          // e.g. a hot news comes,
          // something trending ...
        },
        askUser(type, title, message, options, multiple, timeout, sticky, callback) {
         // console.log('askUser', type, title, message, options, multiple, timeout, sticky, callback);
          __this.setState({refresh: __this.state.refresh + 1, stats: stats});
          // e.g. ask about experince, what rss feeds they want ...
        },
        askUserPermission(type, title, message, sticky, callback) {
         // console.log('askUserPermission', type, title, message, sticky, callback);
          __this.setState({refresh: __this.state.refresh + 1, stats: stats});
          // e.g. read browsing history to build personalized profile
        },

        // todo
        reportError(msg, err) {
          console.error(msg, err);
          __this.setState({refresh: __this.state.refresh + 1, stats: stats});
        }

      }
    );
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState(
      {
        width: getWidth(),
        height: getHeight(),
        hasKeyboard: getScreenSize() === 'Large',
        screenSize: getScreenSize(),
        isLandscape: isLandscape(),
      }
    );
  };
  // <Route path="/about" component={About} />

  // use * route for all routes, since using diff ones, everythingn to same comp, will rerener the comp
  // maybe later use a list a paths /|org/*|site/*|....
  render() {
    // todo ... use context ?

    const _this = this;

    return (
      <div className="App">
        <Router>
          <Route exact path="*" render={(props) => <NewsPage history={props.history}
                                                             stats={_this.state.stats}
                                                             width={_this.state.width}
                                                             height={_this.state.height}
                                                             hasKeyboard={_this.state.hasKeyboard}
                                                             screenSize={_this.state.screenSize}
                                                             isLandscape={_this.state.isLandscape}
                                                   />}
          />
        </Router>
      </div>
    );
  }
}

export default App;
