import DataTable from '../storage/DataTable';

const Parser = require('rss-parser');

// todo this goes into indexer
//let news = new DataTable("_test5", 1, "news");

const proxy = "http://localhost:1234/";
//const proxy = "http://localhost:1234/{URL}";
//const proxy = "//offline.news/proxy/{URL}";

class RssCrawler {
  constructor(url, org) {
    const _this = this;

  let articlesStorage = new DataTable("news-article",
    () => {
      this.parser = new Parser();
      (async () => {
        let feed = await _this.parser.parseURL(proxy + url);

        let now = new Date();

        feed.items.forEach(item => {
          let article = {
            "guid": item.guid || item.link,
            "creator": item.creator,
            "title": item.title,
            "link": item.link,
            "datePublished": item.isoDate ? new Date(item.isoDate) : now,
            //"content": item.content,
            "contentSnippet": item.contentSnippet,
            "tags": item.tags,
            "category": item.category,
            "feedImageUrl": feed.image ? feed.image.url : null,

            "feedUrl": feed.feedUrl,
            "feedTitle": feed.title,
            "feedDescription": feed.description,
            "feedMainSiteLink": feed.link,
            "feedOrd": org,

            // "dateReceived": parseInt(moment(now).format("YYYYMMDDHHmmss"));
            "dateRated": null,
            "rating": 0,
            "read": false,
          };

          if (article.datePublished > now) {
            article.datePublished = now;
          }

          articlesStorage.add(article);

          //// console.log(article);
          //// console.log(Object.keys(item), item)
        });
      })();
    },
    (err) => {}
    );
  }

}

export default RssCrawler;
