import IStreamProvider from './IStreamProvider';
import logger from '../utils/logger';

class CaseStreamProvider extends IStreamProvider {

  query(query, onSuccess, onError) {
    if (query.top) {
      return this._top(query.topArticles, query.topKeywords, onSuccess, onError);
    }
    else if (query.stream) {
      return this._getByStream(query.stream, query.start, query.count, onSuccess, onError);
    }
    else if (query.tag) {
      return this._getByTag(query.tag, query.start, query.count, onSuccess, onError);
    }
    else if (query.org) {
      return this._getByOrg(query.org, query.start, query.count, onSuccess, onError);
    }
    else if (query.domain) {
      return this._getByDomain(query.domain, query.start, query.count, onSuccess, onError);
    }
    else if (query.keyword) {
      return this._getByKeyword(query.keyword, query.start, query.count, onSuccess, onError);
    }
    else {
      logger.fatal("CASE: bad query " + JSON.stringify(query));
    }
  }

  _top(topArticles, topKeywords, onSuccess, onError) {
    logger.fatal("Interaface call - NOP");
  }

  _getByStream(stream, start, count, onSuccess, onError) {
    logger.fatal("Interaface call - NOP");
  }

  _getByTag(tag, start, count, onSuccess, onError) {
    logger.fatal("Interaface call - NOP");
  }

  _getByDomain(domain, start, count, onSuccess, onError) {
    logger.fatal("Interaface call - NOP");
  }

  _getByOrg(org, start, count, onSuccess, onError) {
    logger.fatal("Interaface call - NOP");
  }

  _getByKeyword(keyword, start, count, onSuccess, onError) {
    logger.fatal("Interaface call - NOP");
  }
};

export default CaseStreamProvider;
