import React, {Component} from 'react';

import {
  STREAMS_LIST, STREAMS_DICT,
  IMAGES,
  SM_NONE,
  SM_QUERY,
  SM_ORG,
  SM_DOMAIN,
  SM_TAG,
} from '../../../constants/StreamDisplay';

import './DesktopMenu.scss';

class DesktopMenu extends Component {
  constructor() {
    super();
  }

  // <li key={'all'} className={`menu-stream ${_this.props.highlightStream_ === 'all' && _this.props.stream_ === 'all' ? 'Highlight' : ''} ${_this.props.stream_ === 'all' ? 'active' : ''}`} onClick={() => _this.props.selectStream('all')} >
  //   <span className={`menu-icon icon dripicons-home`}/>&nbsp;&nbsp;&nbsp;<span className="menu-label">Top stories</span>
  // </li>

  render() {
    const _this = this;

    return (
      <div className="DesktopNewsMenu">

        {_this.props.showBranding && <div className="Brand" onClick={() => _this.props.selectStream('all')} ><span className="Offline">Offline</span><span className="Dot">&bull;</span><span className="News">News</span></div>
        }
        <ul className="MainMenu">
        {false && _this.props.searchMode_ !== SM_NONE &&
        <li key={'query'} className={`menu-stream query active`} onClick={() => _this.props.selectStream('all')} >
          <span className={`menu-icon icon dripicons-search`}/>&nbsp;&nbsp;&nbsp;<span className="menu-label">{_this.props.getDisplaySearchTerm()}</span>
          <span className={`menu-icon-close icon dripicons-cross`}/>
        </li>
        }
        {false && _this.props.searchMode_ === SM_NONE &&
          <li key={'search-' + _this.props.searchMode_} className={`menu-stream search`}>
            <span className={`menu-icon icon dripicons-search`}/>&nbsp;&nbsp;&nbsp;<input type="text" placeholder="Search News" className="SearchNews" onKeyDown={_this.props.handleKeyDownInSearch}/>
          </li>
        }

        {[{menu: 'main', stream:'all', icon: 'home', display:'Top stories'}].concat(STREAMS_LIST).filter(stream => stream.menu === "main").map((stream) =>
          <React.Fragment>
          <li key={stream.stream} className={`menu-stream ${_this.props.highlightStream_ === stream.stream && _this.props.stream_ === 'all' ? 'Highlight' : ''} ${_this.props.searchMode_ === SM_NONE && _this.props.stream_ === stream.stream ? 'active' : ''}`} onClick={() => _this.props.selectStream(stream.stream)} >
            {stream.char && <span className={`menu-char`}>{stream.char}</span>}
            {stream.icon && <span className={`menu-icon icon dripicons-${stream.icon}`}/>}
            {(stream.imgActive || stream.img) && <img className={`menu-image menu-image-highlight`} width="28" src={IMAGES[_this.props.highlightStream_ === stream.stream ? stream.imgActive || stream.img : stream.img]}/>}
            &nbsp;&nbsp;&nbsp;<span className={`menu-label ${(stream.imgActive || stream.img) ? 'menu-image-label' : ''}`}>{stream.display}</span>
          </li>
          </React.Fragment>
        )}
        </ul>

        <div key="after-sxs" className="menu-line" />

        <ul className="StreamsMenu">

        {STREAMS_LIST.filter(stream => stream.menu !== "main").map((stream) =>
          <React.Fragment>
          <li key={stream.stream} className={`menu-stream ${_this.props.highlightStream_ === stream.stream && _this.props.stream_ === 'all' ? 'Highlight' : ''} ${_this.props.searchMode_ === SM_NONE && _this.props.stream_ === stream.stream ? 'active' : ''}`} onClick={() => _this.props.selectStream(stream.stream)} >
            {stream.char && <span className={`menu-char`}>{stream.char}</span>}
            {stream.icon && <span className={`menu-icon icon dripicons-${stream.icon}`}/>}
            {stream.img && _this.props.highlightStream_ !== stream.stream && <img className={`menu-image`} width="28" src={stream.img}/>}
            {(stream.imgActive || stream.img) && <img className={`menu-image menu-image-highlight`} width="28" src={IMAGES[_this.props.highlightStream_ === stream.stream ? stream.imgActive || stream.img : stream.img]}/>}
            &nbsp;&nbsp;&nbsp;<span className={`menu-label ${(stream.imgActive || stream.img) ? 'menu-image-label' : ''}`}>{stream.display}</span>
          </li>
          </React.Fragment>
        )}
        </ul>
      </div>
    );
  }
}

export default DesktopMenu;
