// import fetch from 'node-fetch';
import DataTable from '../storage/DataTable';
import {getLastNewsCheckpoint} from '../storage/AppState';
import {setLastNewsCheckpoint} from '../storage/AppState';

import JobArray from './JobArray';

const API_LATEST_NEWS = "/latest/{stream}/{since}";
//const API_LATEST_NEWS = "//offline.news/latest/{stream}/{since}";

class RoseNewsDelta {
  constructor(stream, di) {

    console.log("start fetch news on ", stream)

    const _this = this;

    let url = API_LATEST_NEWS;
    url = url.replace("{stream}", stream);
    url = url.replace("{since}", getLastNewsCheckpoint(stream));

    const actionId = di.createAction('low', 'Loading News: ' + stream, '');

  let articlesStorage = new DataTable("news-article",
    () => {
      fetch(url)
      .then(res => {
        return res.json()
      })
      .then(res => {
        let items = res.items;

        if (!items) return;

        let index = 0;
        let index2 = 0;

        items = items.map((v) => Object.assign(v, {
            "dateRated": null,
            "rating": 0,
            "read": false,
            "clicked": false,
            "inspected": false,
            "words": Object.keys(v._bog.counts),
            "stream": stream,
            "saved": false,  // this is a technicality, to know if we saved the mem article into indexdb - never use from indexdb
            // todo
          }))

        let job = new JobArray(
          items,
          (a) => {
            di.receivedStreamData(stream, items);
          },
          (a, vs, i) => {

              // todo .. add to stories stream

              const f = (j) => {
                items[i+j].saved = true;

                while (index < items.length && items[index].saved) index++;

                const oldIndex2 = index2;
                while (index2 < index && (index >= items.length || items[index2].dateReceived < items[index].dateReceived)) index2++;

                if (oldIndex2 !== index2 && index2 > 0) {
                    setLastNewsCheckpoint(stream, items[index2 - 1].dateReceived);
                    di.savedData(stream, index);
                    di.actionProgress(actionId, "Loading ...", index2, items.length);
                }

                a.remaining = items.length - index2;

               // console.log("remaining", stream, a.remaining, i, j, i+j, items, index, index2);


                if (a.remaining <= 0) {
                  di.actionCompleted(actionId, items.length + ' News Added in stream ' + stream + ', Enjoy!');

                  setLastNewsCheckpoint(stream, res.checkpoint);

                  di.finishedSavingData(stream, items.length);
                }
              };

              articlesStorage.addMany(vs, f, f);
          },
          (a) => {},
          {remaining: items.length},
          100,
          100
        );

        job.start();

      });
    },
    (err) => {
      di.reportError('Error trying to load news from stream ' + stream, err);
    }
    );
  }

}

export default RoseNewsDelta;
