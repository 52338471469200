import logger from '../utils/logger';

// todo Method that subscribes to updates from provider
// add query(query, onResults, onDelta, syncOptions)
// onResult can be an error result also
// sync options can ask to give all results again, or new delta results ?!?
// add a keepAlive pinger
// or add a destroy method ....
// the on delta could show .... N more results if you want ... 
class IStreamProvider {

  query(query, onSuccess, onError) {
    logger.fatal("Interaface call - NOP");
    // e.g. smart.query({top: true}, on1, on2)
    // rest will make url from query
    // will make smart easy to implement
    // will be able to use similar for
  }

  // indexdb will return true if data up to date
  ready() {
    return false;
  }

  _top(onSuccess, onError) {
    logger.fatal("Interaface call - NOP");
  }

  _getByStream(stream, start, count, onSuccess, onError) {
    logger.fatal("Interaface call - NOP");
  }

  _getByTag(tag, start, count, onSuccess, onError) {
    logger.fatal("Interaface call - NOP");
  }

  _getByDomain(domain, start, count, onSuccess, onError) {
    logger.fatal("Interaface call - NOP");
  }

  _getByOrg(org, start, count, onSuccess, onError) {
    logger.fatal("Interaface call - NOP");
  }

  _getByKeyword(keyword, start, count, onSuccess, onError) {
    logger.fatal("Interaface call - NOP");
  }

};

export default IStreamProvider;
