export const STREAMS_LIST = [
  // { stream: "sxs", char:"\u21C4", display: "Side by Side", mustClick: true, menu: "main"},  // TODO use balance image
  { stream: "sxs", img: "/sxs.svg", imgActive: "/sxs_active.svg", display: "Side by Side", mustClick: true, menu: "main"},  // TODO use balance image
  { stream: "realtime", img: "/clockwise_static.png", imgActive: "/clockwise_spin.gif", display: "Realtime", mustClick: true, menu: "main" },
// then line ----
  { stream: "business", icon: "card", display: "Business" },
  { stream: "politics", icon: "user-group", display: "Politics" },
  // https://fontawesome.com/icons/balance-scale?style=solid
  { stream: "technology", icon: "rocket", display: "Technology" },
  { stream: "science", icon: "experiment", display: "Science" },
  { stream: "health", icon: "lifting", display: "Health" },
  // { stream: "world-europe", icon: "web", display: "Europe" },
  // { stream: "world-asia", icon: "web", display: "Asia" },
  // { stream: "world-usa", icon: "web", display: "U.S.A." },
  { stream: "sports", icon: "basketball", display: "Sports" },
  { stream: "life", icon: "preview", display: "Life" },
  { stream: "entertainment", icon: "camcorder", display: "Entertainment" },
  // { stream: "lotto", char:"\uD83C\uDF40", display: "Lotto" },

  // we should remove this category, make clasiffication better
  // { stream: "random", icon: "bell", display: "Other stories", mustClick: true },
];

var _STREAMS_DICT = {};

STREAMS_LIST.forEach((stream) => {
  _STREAMS_DICT[stream.stream] = stream;
});

export const STREAMS_DICT = _STREAMS_DICT;

const clockwise_static = require('../img/clockwise_static.png');
const clockwise_spin = require('../img/clockwise_spin.gif');
const sxs = require('../img/sxs.svg');
const sxs_active = require('../img/sxs_active.svg');

export const IMAGES = {
  "/clockwise_static.png" : clockwise_static,
  "/clockwise_spin.gif" : clockwise_spin,
  "/sxs_active.svg": sxs_active,
  "/sxs.svg": sxs,
};

export const SM_NONE = 'none';
export const SM_QUERY = 'query';
export const SM_ORG = 'org';
export const SM_DOMAIN = 'domain';
export const SM_TAG = 'tag';
