class BackgroundJob {
  constructor() {
    this.completed = false;
    this.errored = false;
  }

  startJob(args, di) {
//    this.startedAt = DateTime.Now();
    this._run(args, di);
  }

  // define _run(args) {}
}

export default BackgroundJob;
