import DataShapes from './DataShapes';

require('indexeddb-getall-shim');

class DataTable {
  constructor(name, onSuccess, onError) {
    this.ready = false;
    this.seed = false;

    const _this = this;
    this.name = name;

    const table = this.table = DataShapes[name];

    this.indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
    // DON'T use "var indexedDB = ..." if you're not in a function.
    // Moreover, you may need references to some window.IDB* objects:
    this.IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction || window.msIDBTransaction;
    this.IDBKeyRange = window.IDBKeyRange || window.webkitIDBKeyRange || window.msIDBKeyRange;
    // (Mozilla has never prefixed these objects, so we don't need window.mozIDB*)

    // Let us open our database, TODO version usage?
    this.request = this.indexedDB.open(table.dbName, table.dbVersion);

    this.request.onupgradeneeded = function(event) {
     // console.log('making a new object store');
      var db = event.target.result;
      if (!db.objectStoreNames.contains(name)) {
        var store = db.createObjectStore(name, {keyPath: table.keyPath, autoIncrement: table.autoIncrement});

        // todo more customized indexes
        for (const [columnName, column] of Object.entries(table.columns)) {
          if (!column.dontIndex) {
            store.createIndex(columnName, columnName, { unique: column.unique, multiEntry: column.multiEntry === false ? false : true });
          }
          // todo prefix, sufix, inside, regex
          // todo range
          // IDBKeyRange.bound("A", "F");
          // todo ignore case
          // composed indexes
          // hashes could be used for pairs?
        }
      }

      _this.seed = true;
    };

    // these two event handlers act on the database being opened
    // successfully, or not
    this.request.onerror = function(event) {
      _this.store = null;
     // console.log("error loading store", event);
      onError(event);
    };

    this.request.onsuccess = function(event) {
      _this.store = _this.request.result;

      if (_this.seed) {
       // console.log("adding", _this.seed);
        _this.addMany(table.seed);
      }

      _this.ready = true;
      onSuccess();
    };
  }

  getAll(onSuccess, onError, max = undefined) {
    var transaction = this.store.transaction(this.name, 'readonly');
    var objectStore = transaction.objectStore(this.name);

    let results = null;

    var getRequest = objectStore.getAll(undefined, max);
    getRequest.onsuccess = function() {
      onSuccess(getRequest.result);
    }
  }

  getByPartialMatch(match, onSuccess, onError) {
    var transaction = this.store.transaction(this.name, 'readonly');
    var objectStore = transaction.objectStore(this.name);

    let results = null;

    // TODO use indexed pairs/triads ... if they exists
    // Indexes to use = ... select them based on columns
    for (const [columnName, value] of Object.entries(match)) {
      var myIndex = objectStore.index(columnName);
      var getRequest = myIndex.getAll(value);
      getRequest.onsuccess = function() {
       // console.log(getRequest.result);
        // todo either make searches in paralel, but we will need to keep all results temporarely
        // or chain them
        // then merge on key
      }
    }
  }

  getByIndex(column, value, start, count, onSuccess, onError) {
    var transaction = this.store.transaction(this.name, 'readonly');
    var objectStore = transaction.objectStore(this.name);

    var myIndex = objectStore.index(column);
    // todo pagination
    var getRequest = myIndex.getAll(value);

    getRequest.onsuccess = function(e) {
      let match = e.target.result || [];
      console.log('returned index call', match);
      onSuccess(match);
    }
    getRequest.onerror = function(e) {
      console.log('error index call', e);
      onError(e);
    }
  }

  getByKey(key, onSuccess, onError) {
    var transaction = this.store.transaction(this.name, 'readonly');
    var objectStore = transaction.objectStore(this.name);

    var myIndex = objectStore.index(this.table.keyPath);
    var getRequest = myIndex.get(key);
    getRequest.onsuccess = function() {
     // console.log(getRequest.result);
    }
  }

  addOne(doc, onSuccess = undefined, onError = undefined) {
    this.addMany([doc], onSuccess, onError)
  }

  addMany(docs, onSuccess = undefined, onError = undefined) {
    var transaction = this.store.transaction(this.name, "readwrite");

    // transaction.oncomplete = function(event) {
    // }
    //
    // transaction.onerror = function(event) {
    //   //if (onError) onError(event);
    //   //console.log("not added");
    //   // TODO use transaction.error;
    // };

    // transaction.onsucess = function(event) {
    //   if (onSuccess) onSuccess(event); // todo ... pass the doc
    // }

    // call an object store that's already been added to the database
    var objectStore = transaction.objectStore(this.name);
    //// console.log(objectStore.indexNames);
    //// console.log(objectStore.keyPath);
    //// console.log(objectStore.name);
    //// console.log(objectStore.transaction);
    //// console.log(objectStore.autoIncrement);

    // Make a request to add our newItem object to the object store

    let i;
    for (i = 0; i < docs.length; i++) {
      const doc = docs[i];
      const idx = i;
      var objectStoreRequest = objectStore.add(doc);
      objectStoreRequest.onsuccess = function(event) {
        if (onSuccess) onSuccess(idx);
       // console.log("added success", doc, event, idx);
        // todo
      };
      // optimist: this is if the doc already exists in db - we should check that
      // pesimist: out of space
      objectStoreRequest.onerror = function(event) {
        if (onError) onError(idx);
       // console.log("added fail", doc, event, idx);
        //console.log("not added");
        // TODO use transaction.error;
      };

    }



  }


  queryWithSelect(select) {
    this.queryWithSelectPlan(this.getSelectPlan(select));
  }

  queryWithSelectPlan(plan) {

  }

  getSelectPlan(select) {

  }

}

export default DataTable;
