import CaseStreamProvider from './CaseStreamProvider';
import DataTable from '../../storage/DataTable';
import logger from '../utils/logger';

// provides data from indexdb
class IndexDbDataProvider extends CaseStreamProvider {
  // indexdb will return true if data up to date
  ready() {
    return false; // todo
  }

  _top(topArticles, topKeywords, onSuccess, onError) {
    onError("IndexDbDataProvider::_top NYI");
    logger.fatal("Interaface call - NOP");
    // todo have top saved in local storage, for now return errm to have rest used
  }

  _getByStream(stream, start, count, onSuccess, onError) {
    onError("IndexDbDataProvider::_getByStream NYI");
    logger.fatal("Interaface call - NOP");

    // logger.info("start loading data from idx index");
    //
    // let articles = new DataTable("news-article",
    //   () => {
    //     articles.getByIndex(
    //       'stream', stream,
    //       start, count,
    //       articles => {
    //         logger.info("loaded data from idx index");
    //         onSuccess(articles);
    //       },
    //       err => {
    //         logger.info("error data from idx index");
    //         onError(articles);
    //       },
    //       undefined
    //     );
    //   },
    //   (err) => {
    //     logger.info("error initfrom idx index");
    //     onError(err);
    //   }
    // );
  }

  _getByTag(tag, start, count, onSuccess, onError) {
    onError("NYI");
    logger.fatal("Interaface call - NOP");
  }

  _getByDomain(domain, start, count, onSuccess, onError) {
    onError("NYI");
    logger.fatal("Interaface call - NOP");
  }

  _getByOrg(org, start, count, onSuccess, onError) {
    onError("NYI");
    logger.fatal("Interaface call - NOP");
  }

  _getByKeyword(keyword, start, count, onSuccess, onError) {
    onError("NYI");
    logger.fatal("Interaface call - NOP");
  }
};

export default IndexDbDataProvider;
