import BackgroundJob from './BackgroundJob';
import RoseNewsDelta from '../crawler/RoseNewsDelta';
import RoseWebsitesDelta from '../crawler/RoseWebsitesDelta';

class FetchNewsBackgroundJob extends BackgroundJob {
  _run(streams, di) {
    // TODO
   // console.log(streams);

    new RoseWebsitesDelta(di);

    let timeoutNow = 0;
    let deltaTimeout = 60 * 3 / 10 * 1000;
    streams.forEach((stream) => {
      setTimeout(() => {
        if (!di.wipStream(stream)) {
          let rss = new RoseNewsDelta(stream, di);
        }
      }, timeoutNow);
      timeoutNow += deltaTimeout;
    });
  }
}

export default FetchNewsBackgroundJob;
