import BackgroundJob from './BackgroundJob';
import RssCrawler from '../crawler/RssCrawler';

class FetchRssBackgroundJob extends BackgroundJob {
  _run(args, di) {
    // TODO
   // console.log(args);
    let rss = new RssCrawler(args);
  }
}

export default FetchRssBackgroundJob;
