
class JobArray {
  constructor(array, onStart, onItems, onEnd, accumulator, itemsAtATime, breakTimeMs) {
    this.array = array;
    this.onStart = onStart;
    this.onItems = onItems;
    this.onEnd = onEnd;
    this.accumulator = accumulator;
    this.itemsAtATime = itemsAtATime;
    this.breakTimeMs = breakTimeMs;
  }

  start() {
    this.index = 0;

    this.onStart(this.accumulator);

    this.runN(this);
  }

  runN(_this) {

    if (_this.index >= _this.array.length) {
      _this.onEnd(_this.accumulator);
      return;
    }

    if (_this.index < _this.array.length) {
      _this.onItems(_this.accumulator, _this.array.slice(_this.index, _this.index + _this.itemsAtATime), _this.index);
      _this.index += _this.itemsAtATime;
    }

    setTimeout(() => _this.runN(_this), _this.breakTimeMs);
  }

}

export default JobArray;
