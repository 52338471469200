import {DB_VERSION} from '../constants/Version';

export function getLastNewsCheckpoint(stream) {
  const key = "nc-" + DB_VERSION + "-" + stream;
  let checkpoint = window.localStorage.getItem(key);
  return checkpoint || 20190101000000;
}

export function setLastNewsCheckpoint(stream, checkpoint) {
  const key = "nc-" + DB_VERSION + "-" + stream;
  window.localStorage.setItem(key, checkpoint);
}

export function getGlobal(variable) {
  const key = "global-" + DB_VERSION + "-" + variable;
  let data = window.localStorage.getItem(key);
  return data;
}

export function setGlobal(variable, value) {
  const key = "global-" + DB_VERSION + "-" + variable;
  window.localStorage.setItem(key, value);
}
